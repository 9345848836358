import React, { useEffect, useState } from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import './ReportPage.css';

const categoryMap = {
    1: 'what_to_do',
    2: 'what_to_eat',
    3: 'what_to_watch',
    4: 'where_to_go',
    5: 'what_to_play',
    6: 'surprise_me',
};

const API_ENDPOINTS = {
    feedbackForms: 'https://picktory-report-backend.onrender.com/api/reports/feedback-form-submissions',
    categoryWise: 'https://picktory-report-backend.onrender.com/api/reports/category-wise-suggestions',
    feedbackTrends: 'https://picktory-report-backend.onrender.com/api/reports/feedback-trends'
};

const ReportPage = () => {
    const [feedbackFormData, setFeedbackFormData] = useState([]);
    const [categoryWiseData, setCategoryWiseData] = useState([]);
    const [feedbackTrendsData, setFeedbackTrendsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [feedbackFormState, setFeedbackFormState] = useState({
        skip: 0,
        take: 10,
        sort: [],
        filter: [],
        group: [],
    });

    const [categoryWiseState, setCategoryWiseState] = useState({
        skip: 0,
        take: 10,
        sort: [],
        filter: [],
        group: [],
    });

    const [feedbackTrendsState, setFeedbackTrendsState] = useState({
        skip: 0,
        take: 10,
        sort: [],
        filter: [],
        group: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [feedbackFormsResponse, categoryWiseResponse, feedbackTrendsResponse] = await Promise.all([
                    axios.get(API_ENDPOINTS.feedbackForms),
                    axios.get(API_ENDPOINTS.categoryWise),
                    axios.get(API_ENDPOINTS.feedbackTrends),
                ]);

                setFeedbackFormData(feedbackFormsResponse.data);

                const dataWithCategoryNames = categoryWiseResponse.data.map(item => ({
                    ...item,
                    category_id: categoryMap[item.category_id] || item.category_id,
                }));
                setCategoryWiseData(dataWithCategoryNames);

                setFeedbackTrendsData(feedbackTrendsResponse.data);
            } catch (error) {
                console.error("Error fetching data!", error);
                alert("An error occurred while fetching data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDataStateChange = (e, setState) => {
        setState(e.dataState);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="report-container">
            <div className="report-section">
                <h1>Geri Bildirim Formu Gönderimleri</h1>
                <Grid
                    data={process(feedbackFormData, feedbackFormState)}
                    pageable
                    sortable
                    filterable
                    {...feedbackFormState}
                    onDataStateChange={(e) => handleDataStateChange(e, setFeedbackFormState)}
                >
                    <GridColumn field="name" title="Ad" />
                    <GridColumn field="email" title="E-posta" />
                    <GridColumn field="message" title="Mesaj" />
                    <GridColumn field="created_at" title="Gönderim Tarihi" />
                    <GridNoRecords>
                        Kayıt bulunamadı.
                    </GridNoRecords>
                </Grid>
            </div>

            <div className="report-section">
                <h1>Kategoriye Göre Öneriler</h1>
                <Grid
                    data={process(categoryWiseData, categoryWiseState)}
                    pageable
                    sortable
                    filterable
                    {...categoryWiseState}
                    onDataStateChange={(e) => handleDataStateChange(e, setCategoryWiseState)}
                >
                    <GridColumn field="category_id" title="Kategori" />
                    <GridColumn field="suggestion_tr" title="Öneri (TR)" />
                    <GridColumn field="like_count" title="Beğeni" />
                    <GridColumn field="dislike_count" title="Beğenmeme" />
                    <GridNoRecords>
                        Kayıt bulunamadı.
                    </GridNoRecords>
                </Grid>
            </div>

            <div className="report-section">
                <h1>Günlere Göre Geri Bildirim</h1>
                <Grid
                    data={process(feedbackTrendsData, feedbackTrendsState)}
                    pageable
                    sortable
                    filterable
                    {...feedbackTrendsState}
                    onDataStateChange={(e) => handleDataStateChange(e, setFeedbackTrendsState)}
                >
                    <GridColumn field="date" title="Tarih" />
                    <GridColumn field="like_count" title="Beğeni" />
                    <GridColumn field="dislike_count" title="Beğenmeme" />
                    <GridNoRecords>
                        Kayıt bulunamadı.
                    </GridNoRecords>
                </Grid>
            </div>
        </div>
    );
};

export default ReportPage;
